import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { BaseService } from 'src/app/base/service/base.service';
import { userApi } from 'src/app/base/model/constants/ApiEndPoints';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import { CustomToastrServiceService } from 'src/app/modules/base/services/custom-toastr-service.service';
import { ZipPattern } from 'src/app/base/model/constants/ZipPattern';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-personal-detail',
  templateUrl: './personal-detail.component.html'
})
export class PersonalDetailComponent implements OnInit,OnDestroy {
  constructor(
    private fb: FormBuilder,
    private _baseService: BaseService,
    private toastr: ToastrService,
    private userService: UserService,
    private router: Router,
    private customToastrService: CustomToastrServiceService,
    private translate: TranslateService
  ) { }

  changePasswordForm: FormGroup;
  updateProfileForm: FormGroup;
  deleteAccountForm: FormGroup;
  profileImage: any;
  fileName = '';
  isActive: boolean = true;
  isActivechange: boolean = false;
  isActiveW9: boolean = false;
  findAvtar;
  show = false;
  showConfirmPassword = false;
  showNewPassword = false;
  profiles;
  loading: boolean;
  personalEmail;
  personalDob;
  personalGender;
  successMessage = false;
  isVerified = false;
  errorMessage = false;
  avatarDataUrl: string;
  userProfile;
  showMessage;
  responseMessage = {
    error: 'error',
    info: 'info',
    success: 'Profile Updated Succefully',
    warning: 'warning',
  };
  zipPatternObjects: Array<ZipPattern> = [];
  showZipErrorMessage: boolean = false;
  isSubmitAllowed: boolean = false;
  zipErrorMessage: string;
  regexForZip;
  zipValidation = require('../../../../../assets/ZipValidationPatterns.json');
  mobileIsdCode = require('../../../../../assets/MobileIsdCode.json');
  userCountryId = localStorage.getItem('countryId');
  iSDCode;
  selectedAvatarIndex:number = -1;
  uploadW9Form: FormGroup;
  w9FormFile;
  isSubmitW9Form:boolean;
  w9FormMessage:string;
  isW9FormSubmit:boolean;
  isShowW9Form:boolean;
  isShowFormLoader:boolean;
  isW9FormUploaded:boolean;
  changeUploadedBtn:boolean;
  uploadedFileName = this.translate.instant('No File Selected');
  fw9Url;
  fileError:boolean;
  truncatedText = this.translate.instant('No File Selected')
  downloadFileName;
  setUploadedFile;
  isDeleteBtnDisabled:boolean;
  userEmail;
  userGender;
  userDob;
  passwordStrength:any;
  showCriteria:boolean;
  showConfirm:boolean;
  isLoading:boolean;
  ngOnInit() {
    this.updateProfileForm = this.fb.group({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      mobile: new FormControl('', [Validators.maxLength(15)]),
      address: this.fb.group({
        address: [''],
        city: [''],
        state: [''],
        zipCode: ['', [Validators.required]]
      })
    });

    this.profiles = localStorage.getItem('profileImage')
    const getPanelistId = localStorage.getItem('panelistId')

    // Method for change password
    this.changePasswordForm = this.fb.group({
      currentPassword: new FormControl('', [Validators.required]),
      newPassword: new FormControl('', [Validators.required,this.noSpaceValidator,
      Validators.pattern('^(?=.*[A-Za-z])(?=.*[0-9])(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+].{7,}'),
      Validators.minLength(8)]),
      confirmPassword: new FormControl('', [Validators.required]),
    }, {
      validators: this.passwordMatchValidator.bind(this)
    })
    setTimeout(() => {
      this.selectButton(this.userService.selectedTab);
    }, 0);

    this.uploadW9Form = this.fb.group({
      firstName:['',Validators.required],
      lastName:['',Validators.required],
      address:['',Validators.required],
      city:['',Validators.required],
      state:['',Validators.required],
      ssn:['',[Validators.required, Validators.maxLength(9), Validators.minLength(9), this.noSpaceValidator, this.specialCharacterValidator]],
      w9Form:['']
    })

    // Method for getAvatar
    this._baseService.get(userApi.getAvatar).then((res: any) => {
      this.findAvtar = res.data;
      const profileImage = localStorage.getItem('profileImage');
      this.findAvtar.forEach((element,index) => {
        if (profileImage === element.img) {
          this.selectedAvatarIndex = index;
        }
      });
      this.getUserProfile();
      this.checkFormSubmitted();
    })

    // Method for delete account form
    this.deleteAccountForm = this.fb.group({
      reasontxt: new FormControl(''),
      // checkboxSelect: new FormControl('', Validators.requiredTrue)
    })

   

    this.zipPatternObjects = this.zipValidation.filter(e => e.countryids.find(l => l == this.userCountryId));
    this.regexForZip = new RegExp(this.zipPatternObjects[0].patterns[0]);

  }

  // Method for getUserProfile
  getUserProfile(){
    this._baseService.get(userApi.getUserProfile).then((res: any) => {
      this.userProfile = res.token ? res.data[0] : res;
      if ( this.userProfile.isEmailVerified == true) {
        this.isVerified = true;
      }

      this.updateFormValue(this.userProfile);
      const countryCode =  this.userProfile.countryCode;
      const isdCode = this.mobileIsdCode[0][countryCode];
      this.iSDCode = isdCode;
      if ( this.userProfile.isEmailVerified == false) {
        this.customToastrService.showCustomToastr('', '')
      }
      this.userService.getuserTotalPoint();
    })
  }

  checkFormSubmitted(){
    this._baseService.get(userApi.checkFormSubmitted).then((response:any) => {
      this.isSubmitW9Form = response.isFormSubmitted
      if (this.isSubmitW9Form) {
        this.isShowW9Form = true;
        this.fw9Url = response.fw9Url
        this.w9FormMessage= this.translate.instant('formAlreadySubmit');
        this.w9FormFile = '';
        this.uploadedFileName = response.filename
        this.uploadW9Form.patchValue({
          firstName: response.firstName,
          lastName: response.lastName,
          address: response.address,
          city: response.city,
          state: response.state,
          ssn: response.ssn,
          w9Form: response.fw9Url
        })
      }
    }).catch((error:any) => {
      this.uploadW9Form.get('w9Form').setValidators(Validators.required);
      this.uploadW9Form.get('w9Form').updateValueAndValidity();
      this.isShowW9Form = false;
      this.isSubmitW9Form = error.isFormSubmitted
    })
  }

  onUpdateW9Form(){
   this.isShowW9Form = false;
   this.w9FormFile = '';

   this._baseService.get(userApi.checkFormSubmitted).then((response:any) => {
    this.truncatedText = this.trancateWord(response.filename, 20)
    this.downloadFileName = this.trancateWord(response.filename, 10)
    this.fw9Url = response.fw9Url
    this.setUploadedFile = response
  }).catch((error:any) => {
    this.uploadW9Form.get('w9Form').setValidators(Validators.required);
    this.uploadW9Form.get('w9Form').updateValueAndValidity();
    this.isShowW9Form = false;
    this.isSubmitW9Form = error.isFormSubmitted
  })

  }

  // Method for update userProfile
  onUpdate() {
    this.zipErrorMessage = '';
    if (this.updateProfileForm.valid) {
      this.loading = true;
      const userZipCode = this.updateProfileForm.value.address.zipCode.replace(/\s/g, '').toUpperCase();
      this.updateProfileForm.patchValue({
        address: {
          zipCode: userZipCode
        }
      })
      this.userProfile = this.updateProfileForm.value;
      if (this.regexForZip.test(userZipCode)) {
        this._baseService.patch(userApi.updatePanelistProfile, this.userProfile).then((res: any) => {
          this.loading = false;
          this.userService.sendProfile.next(res)
          this.translate.get('profileUpdate').subscribe((translate) => {
            this.toastr.success(translate, '', {
              toastClass: 'success-toast',
              timeOut: 3000,
            })
          })

          this.profiles = localStorage.getItem('profileImage')
          this.zipErrorMessage = '';
        })
      }
      else {
        this.translate.get('zipValidInfoMessage').subscribe((translate) => {
          this.zipErrorMessage = translate + this.zipPatternObjects[0].message;
        })
      }
    } else {
      const userZipCode = this.updateProfileForm.get('address.zipCode').value;
      this.translate.get('zipValidInfoMessage').subscribe((translate) => {
        this.zipErrorMessage = this.regexForZip.test(userZipCode)
          ? ''
          : translate + this.zipPatternObjects[0].message;
      })

    }
  }


  selectButton(button: string) {
    this.isActive = button === 'myProfile';
    this.isActivechange = button === 'chnagePassword';
    this.isActiveW9 = button === 'w9Form';
    // this.uploadW9Form.get('w9Form').reset();
    this.w9FormFile = '';
  }

  // Method for delete account 
  onDeleteAccount() {
    this.isDeleteBtnDisabled = true;
    this.markFormGroupTouched(this.deleteAccountForm)
    const getPanelistId = localStorage.getItem('panelistId')
    const body = {
      isActive: false,
      deleteReason: this.deleteAccountForm.value.reasontxt
    }

  if (this.deleteAccountForm.valid) {
    this._baseService.patch(userApi.deleteAccount, body).then((res: any) => {
      this.translate.get('accountDeletedSuccess').subscribe((translate) => {
        this.toastr.success(translate, '', {
          toastClass: 'success-toast',
          timeOut: 3000,
        })
      })
      if (res) {
        this.router.navigate(['/'])
        localStorage.clear();
        window.location.reload()
      }
    })
  }
  }

  updateFormValue(UserDate){
    this.userEmail = UserDate.email;
    this.userGender = UserDate.gender;
    const dobParts = UserDate.dob.split('-'); // Split the string by '-'
    // Extract the month and year
    const monthNumber = parseInt(dobParts[1], 10) - 1; // Convert to 0-based index (January = 0)
    const getMonth = this.translate.instant('MONTHS.' + Object.keys(this.translate.instant('MONTHS'))[monthNumber]);// Month is in dobParts[1], which is 1-based
    const getYear = dobParts[0];
    this.userDob = `${getMonth} ${getYear}`;
    this.updateProfileForm.setValue({
      firstName: UserDate.firstName,
      lastName: UserDate.lastName,
      mobile: UserDate.mobile,
      address: {
        address: UserDate.address.address,
        city: UserDate.address.city,
        state: UserDate.address.state,
        zipCode: UserDate.address.zipCode
      }
    })
  }

  // Method for not allowed alphabets for mobile
  onInputKeyDown(event: KeyboardEvent) {
    const inputKey = event.key;
    const isAlphabet = /^[a-zA-Z]$/.test(inputKey);
    const inputLength = (event.target as HTMLInputElement).value.length;
    const maxLength = 12;
    if (inputLength >= maxLength) {
      event.preventDefault();
    }
    if (isAlphabet) {
      event.preventDefault();
    }
  }
  // Method for not allowed alphabets for zipcode


  // method for update Avatar
  updateAvatar(avatar,index) {
    const getPanelistId = localStorage.getItem('panelistId')
    const filedata = new FormData();
    filedata.append('avatarUrl', avatar.img)
    this.profiles = avatar.img;
    this.userService.sendAvatar.next(avatar.img);
    this.selectedAvatarIndex = index;
    this._baseService.post(userApi.uploadUserProfileImage, filedata).then((res: any) => {
      localStorage.setItem('profileImage', res.panelistImage);
    })
    this.translate.get('updateAvatar').subscribe((translate) => {
      this.toastr.success(translate, '', {
        toastClass: 'success-toast',
        timeOut: 3000,
      })
    })
  }

  // method for change Password
  onChangePassword() {
    this.isLoading = true;
    let oldPassword = this.changePasswordForm.value.currentPassword;
    let newPassword = this.changePasswordForm.value.newPassword;
    this.checkPasswordStrength('');
   if (oldPassword == newPassword) {
    this.isLoading = false;
    this.changePasswordForm.get('newPassword').setValue('')
    this.changePasswordForm.get('confirmPassword').setValue('');
    this.translate.get('passwordSameMessage').subscribe((translate) => {
      this.toastr.error(translate)
    })
    return;
   }
    const getPanelistId = localStorage.getItem('panelistId')
    const body = {
      panelistId: getPanelistId,
      password: this.changePasswordForm.value.currentPassword,
      newPassword: this.changePasswordForm.value.newPassword
    }
    this._baseService.patch(userApi.changePassword, body).then((res: any) => {
      if (res) {
        this.translate.get('passwordChanged').subscribe((translate) => {
          // this.showMessage = translate;
          this.toastr.success(translate, '', {
            toastClass: 'success-toast',
            timeOut: 3000,
          })
        })
        this.isLoading = false;
        this.changePasswordForm.reset();
      }
    }, error => {
      this.isLoading = false;
      this.translate.get('incorrectOldPassword').subscribe((translate) => {
        // this.showMessage = translate;
        this.toastr.error(translate)
      })
    })

  }



  // Method for verify email
  verifyEmail() {
    this.userService.verifyEmail(this.userProfile.email).then((res: any) => {
      this.translate.get('sentVerificationEmail').subscribe((translate) => {
        this.toastr.success(translate, '', {
          toastClass: 'success-toast',
          timeOut: 3000,
        })
      })
    }).catch((res) => {
      // console.log(res);

      this.toastr.error(res.error.message)
    })
  }

  // upload image
  uploadedImage!: File;
  dbImage: any;
  postResponse: any;
  successResponse: string | undefined;
  image: any;


  ChangeProfileImage(image: any) {
    this.profileImage = image
  }
  openPassword() {
    this.show = !this.show
  }
  openNewPassword() {
    this.showNewPassword = !this.showNewPassword
  }
  validateInputText(event: KeyboardEvent) {
    const inputChar = String.fromCharCode(event.keyCode);
    const pattern = /[A-Za-z\s]/;
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  // Method to handle ZIP code validation on input change
  onZipValidation(event: any) {
    const zipCode = event.target.value;
    const removeSpaces = zipCode.replace(/\s/g, '').toUpperCase();
    const isZipValid = this.regexForZip.test(removeSpaces);
    this.showZipErrorMessage = !isZipValid;
    this.isSubmitAllowed = isZipValid;
    if (isZipValid) {
      this.zipErrorMessage = '';
    } else {
      this.translate.get('zipValidInfoMessage').subscribe((translate) => {
        this.zipErrorMessage = translate + this.zipPatternObjects[0].message;
      })
    }
  }


  validationMobile(event: KeyboardEvent) {
    const inputValue = String.fromCharCode(event.keyCode);
    const pattern = /^[0-9]+$/
    if (!pattern.test(inputValue)) {
      event.preventDefault();
    }
  }

  openConfirmPassword() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  get f() {
    return this.changePasswordForm.controls;
  }
  confirmedValidator(newPassword, confirmPassword) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[newPassword];
      const matchingControl = formGroup.controls[confirmPassword];
      if (matchingControl.errors && !matchingControl.errors['confirmedValidator']) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  } 
   
  noSpaceValidator(control: AbstractControl): ValidationErrors | null {
    if (/\s/.test(control.value)) {
      return { noSpace: true };
    } else {
      return null;
    }
  }

  specialCharacterValidator(control: AbstractControl): ValidationErrors | null {
    const regex = /[^\w\s]/;
    
    if (regex.test(control.value)) {
      return { specialCharacter: true };
    } else {
      return null;
    }
  }
  

  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    })
  }

  submitW9Form() {   
    this.userService.markFormGroupTouched(this.uploadW9Form)
    const selectFileType = this.w9FormFile ? this.w9FormFile.name : this.setUploadedFile.filename
    const fileType = selectFileType.split('.').pop().toLowerCase();
      if (this.uploadW9Form.invalid) {
        if (this.uploadW9Form.get('ssn').invalid) {
          this.translate.get('ssnNoError').subscribe(res=>{
            this.toastr.error(res)
          })
          return;
        }
        if (this.uploadW9Form.get('w9Form').invalid) {
          this.toastr.error('Upload W-9 Form')
          return;
        }
      } else {
        if (fileType === 'pdf' || fileType === 'jpeg' || fileType === 'jpg') {
        this.isShowFormLoader = true;
        const fileData = new FormData();
        fileData.append('firstName', this.uploadW9Form.value.firstName)
        fileData.append('lastName', this.uploadW9Form.value.lastName)
        fileData.append('ssn', this.uploadW9Form.value.ssn)
        fileData.append('address', this.uploadW9Form.value.address)
        fileData.append('city', this.uploadW9Form.value.city)
        fileData.append('state', this.uploadW9Form.value.state)
        fileData.append('file',this.w9FormFile)
        // Call w-9 form submission API
       this._baseService.post(userApi.formUpload,fileData).then((res) => {
        this.isShowFormLoader = false;
        this.isShowW9Form = true;
        this.w9FormMessage = this.translate.instant('submitSuccess')
       }).catch((err)=>{
        this.translate.get('formSubmissitonFailed').subscribe(res=>{
          this.toastr.error('Form submission failed')
        })
        this.isShowFormLoader = false;
       })
        } else {
          this.toastr.error('Select only PDF file or JPEG format')
        }
      }
  }


  onFileSelected(item) {
    this.fw9Url=''
    this.w9FormFile = item.target.files[0];
    this.uploadedFileName = this.w9FormFile.name
    if (this.w9FormFile) {
      const fileName = this.uploadedFileName;
      const fileType = fileName.split('.').pop().toLowerCase();
      if (fileType === 'pdf' || fileType === 'jpeg' || fileType === 'jpg') {
        this.fileError = false;
        this.isW9FormUploaded = true;
        this.changeUploadedBtn = true;
      } else {
        this.fileError = true;
        item.target.value = null;
        this.isW9FormUploaded = false;
        this.changeUploadedBtn = false;
      }
      this.truncatedText = this.trancateWord(this.uploadedFileName, 28)
  }

}

  ngOnDestroy() {
   this.userService.selectedTab = 'myProfile'
  }

  trancateWord(text, maxLength){
    let truncatedText = maxLength
    if (text.length > maxLength) {
      truncatedText = text.substr(0, maxLength) + '...';
    } else {
      truncatedText = this.uploadedFileName;
    }
    return truncatedText;
  }

  checkPasswordStrength(event: any) {
    const eventValue = event.value;
    const password = eventValue == undefined ? event : eventValue;
      this.userService.checkPasswordStrength(password);
      this.passwordStrength = {
        hasLetter: this.userService.hasLetter,
        hasNumber: this.userService.hasNumber,
        hasSpecialChar: this.userService.hasSpecialChar,
        hasMinLength: this.userService.hasMinLength,
        hasSpace: this.userService.hasSpace
      };
    }
    passwordMatchValidator(formGroup: FormGroup) {
      const password = formGroup.get('newPassword').value;
      const confirmPassword = formGroup.get('confirmPassword').value;
      return password === confirmPassword ? null : { passwordMismatch: true };
    }
}

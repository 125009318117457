<app-nav-menu></app-nav-menu>
<hr class=" container-fluid nav-devider">

<div class="panel-summary">

  <section class="container-fluid loginpage">
    <div class="row landingpage-data">
      <div class="col-md-6">
        <section>
          <div class="landingpage-header">
            <h1 class="t-header">
              {{'loginTitle'|translate}} <span class="landingpage-earn">{{'earn'|translate}}</span>
              {{'rewards'|translate}}
            </h1>
          </div>
          <div>
            <p class="t-subheader">
              {{'loginText'|translate}}
            </p>
          </div>
          <div class="mt-auto joinpanel-link">
            <a routerLink="/create-account">{{'joinNow'|translate}}<i
                class="fa-sharp fa-solid fa-arrow-right mx-2"></i></a>
          </div>
        </section>

        <!-- <app-fingerprint-component></app-fingerprint-component> -->
      </div>
      <div class="col-md-1"></div>
      <div class="col-md-5 login-form" *ngIf="!isEmailVerified">
        <!--user Login Form-->
        <form [formGroup]="loginForm" (ngSubmit)="userLogin()">
          <div class="login-opinion-edge">
            <section>
              <div class="form-heading px-2 mx-2">
                <h3 class="fs-5">{{'signIn'|translate}}</h3>
              </div>
            </section>
            <div class="mb-3 txt-group">
              <div [ngClass]="loginForm.get('email')?.invalid && loginForm.get('email')?.touched ? 'invalid-input':'' ">

                <input type="email" formControlName="email" value="" class="form-control revalidation-password"
                  [placeholder]="'email'|translate" id="txtEmail">
              </div>
              <!-- <label class="form-label">{{'emailAddress'|translate}}</label> -->
            </div>
            <!-- <div class="mt-1 px-2 text-secondary"
              *ngIf="email.invalid && (email.dirty || email.touched)">
              <div class="fs-6" *ngIf="email.errors?.['required']">{{'emailErrorMessage'|translate}}.</div>
              <div class="fs-6" *ngIf="email.errors?.['email']">
               {{'validEmail'|translate}}
              </div>
            </div> -->
            <div class="mb-3 txt-group">
              <!-- <label class="form-label">{{'password'|translate}}</label> -->
              <div
                [ngClass]="loginForm.get('password')?.invalid && loginForm.get('password')?.touched ? 'invalid-input input-group':'input-group' ">
                <input type="password" [type]="!show ? 'password' : 'text'" formControlName="password" value=""
                  [placeholder]="'password'|translate" class="form-control revalidation-password" id="txtPassword"
                  required autocomplete="off">
                <span class="input-group-text icon-bottum">
                  <span (click)="openPassword()" *ngIf="!show"><i class="fa-regular fa-eye-slash"></i></span>
                  <span (click)="openPassword()" *ngIf="show"><i class="fa-sharp fa-regular fa-eye"></i></span>
                </span>
              </div>
            </div>

            <!-- <div class="mb-3 px-2">
              <ngx-recaptcha2 [siteKey]="recaptchaSiteKey" [hl]="getCulture"
                formControlName="recaptcha" class="recaptcha">
              </ngx-recaptcha2>
            </div> -->

            <!-- <div class="mt-1 px-2 text-secondary"
            *ngIf="password.invalid && (password.dirty || password.touched)">
              <div class="fs-6" *ngIf="password.errors?.['required']">{{'passwordErrorMessage'|translate}}.</div>
            </div> -->
            <button type="submit" class="btn btn-primary loginbtn" *ngIf="!isShowLoader">{{'login'|translate}}</button>
            <button class="btn btn-primary loginbtn" *ngIf="isShowLoader">
              <div class="spinner-border spinner-border-sm" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </button>
            <div class="d-flex justify-content-between remember-user">
              <div class="mb-3 form-check">
                <!-- <input type="checkbox" class="form-check-input" id="chkCheckbox">
                <label class="form-check-label" for="chkCheckbox">{{'rememberMe'|translate}}</label> -->
              </div>
              <div class="mb-3">
                <a class="forgot-password" data-bs-toggle="modal"
                  data-bs-target="#forgetPassword">{{'forgotPassword'|translate}}?</a>
              </div>
            </div>
            <!-- <div class="text-secondary mx-2 fs-6" *ngIf="showMessege">
              {{errorMessage}}
            </div> -->
          </div>
        </form>

        <div class="d-flex flex-wrap px-2 py-1">
          <div class=" p-0 fs-6 mt-1 mb-3">
            <small>{{'availableWith'|translate}}</small>
          </div>
          <div class="d-flex ml-auto mb-2 p-0 available-app">
            <div class="app-play bg-dark">
              <img class="play-icon" src="../assets/image/google-play.png" alt="">
              <span><a href="https://play.google.com/store/apps/details?id=com.unipanel&pli=1" class="text-light">Google
                  Play</a></span>
            </div>
            <div class="app-play bg-dark">
              <i class="fa-brands fa-apple text-light"></i>
              <span><a href="https://apps.apple.com/us/app/opinion-edge/id6446476343" class="text-light">App
                  Store</a></span>
            </div>
          </div>

        </div>
      </div>
      <div class="col-md-5 login-form" *ngIf="isEmailVerified">
        <div class="verification-div d-flex justify-content-center mb-2">
          <div class="text-center">
            <div class="text-center text-primary circle-check-div">
              <i class="fa-sharp fa-solid fa-triangle-exclamation fs-1"></i>
            </div>
            <div class="p-1">
              <h2 class="fw-bold fs-3">{{'verifyToContinue' | translate}}</h2>
              <!-- <p class="text-primary">{{emailLink}}</p> -->
              <div class="verifymessage-box fs-6 ">
                {{'thanksText'|translate}}
              </div>
              <div class="mt-3 fs-6 mb-3">
                <div class="mt-3 text-center">{{'getInTouch'|translate}} </div>
                <div><span class="text-primary">support@opinion-edge.com</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal for forget Password -->
      <form [formGroup]="forgetPasswordForm" (ngSubmit)="onForgotPassword()">
        <div class="modal fade" id="forgetPassword" tabindex="-1" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h3 class="modal-title fs-5 reset-password">{{'resetPassword'|translate}}</h3>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body row">
                <div class="col-md-12 forgot-password-box">
                  <!-- <label for="inputEmail4" class="form-label">Email</label> -->
                  <input type="email" formControlName="forgotEmail" [placeholder]="'enterYourMessage'|translate" class="form-control">
                </div>
              </div>
              <div class="modal-footer d-flex justify-content-between ">
                <section class="px-3">
                  <small class="text-end text-danger fw-bold"
                *ngIf="showErrorMessage">{{showResponseMessage}}</small>
              <small class="text-end text-danger fw-bold"
                *ngIf="showSuccessMessage">{{showSuccessResponse}}</small>
              <div class="text-danger"
                *ngIf="forgotEmail.invalid && (forgotEmail.dirty || forgotEmail.touched)">
                <div class="fs-6" *ngIf="forgotEmail.errors?.['required']">{{'emailErrorMessage'|translate}}.</div>
                <div class="fs-6" *ngIf="forgotEmail.errors?.['email']">{{'validEmailMessage'|translate}}.</div>
              </div>
                </section>
                <button type="submit" class="btn btn-primary btn-sm" [disabled]="forgetPasswordForm.invalid"
                  data-bs-dismiss="modal" aria-label="Close" *ngIf="!responseLoader">{{'submit'|translate}}</button>
                <button class="btn btn-primary btn-sm" type="button" *ngIf="responseLoader">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  {{'loading'|translate}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </section>

  <section class="container-fluid">
    <div class="row mt-5 panel-register">
      <div class="col-md-4 register-panel">
        <div>
          <div class="text-primary text-center px-2 fs-6"><i class="fa-sharp fa-solid fa-circle text-center"></i></div>
          <div>
            <h3 class="panel-text-header">{{'registerUnipanel'|translate}}</h3>
            <!-- <p class="text-center">{{'signUpPointBonus'|translate}}.</p> -->
          </div>
          <div class="img-register-panel">
            <img src="../assets/image/shiny-socializing.png" alt="" />

          </div>
        </div>
      </div>
      <div class="col-md-4 mb-5 earnreward">
        <div class="img-earnreward">
          <img src="../assets/image/Opinion Edge.png" alt="" />
        </div>
        <section>
          <div class="text-center mt-3">
            <span class="text-primary  px-2 fs-6"><i class="fa-sharp fa-solid fa-circle text-center"></i></span>
            <span class="text-primary  px-2 fs-6"><i class="fa-sharp fa-solid fa-circle text-center"></i></span>
            <span class="text-primary  px-2 fs-6"><i class="fa-sharp fa-solid fa-circle text-center"></i></span>
          </div>
          <div>
            <h3 class="panel-text-header">{{'earnReward'|translate}}</h3>
            <p>
              {{'earnRewardSubTitle'|translate}}
            </p>
          </div>
        </section>
      </div>
      <div class="col-md-4 participate-survey mb-3">
        <section>
          <div class="text-center">
            <span class="text-primary  px-2 fs-6"><i class="fa-sharp fa-solid fa-circle text-center"></i></span>
            <span class="text-primary  px-2 fs-6"><i class="fa-sharp fa-solid fa-circle text-center"></i></span>
          </div>
          <div>
            <h3 class="panel-text-header">{{'participateSurvey'|translate}}</h3>
            <p>{{'participateSurveySubTitle'|translate}}</p>
          </div>
        </section>
        <div class="img-participate-survey">
          <img src="../assets/image/shiny-happy.png" alt="" />
        </div>
      </div>
    </div>
  </section>

  <section class="container-fluid reward-options mt-3">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 mb-3">
          <h4 class="panel-text-header text-center">{{"rewardHeader"|translate}}</h4>
        </div>
        <div class="col-12 col-sm-6 col-md-4 mb-3 d-flex justify-content-center">
          <div class="reward-img__wrap w-65">
            <img class="reward-img tango-img rounded shadow" src="../../../../../assets/image/Tango_reward.jpg"
              alt="Reward Link India">
              <div class="reward-flag-login" *ngIf="countryCode">
                <div class="">{{countryCode.toUpperCase()}}</div>
                <div>
                  <span class="{{'fi fi-'+ countryCode}}"></span>
                </div>
              </div>
          </div>
        </div>

        <div class="col-12 col-sm-6 col-md-4 mb-3 d-flex justify-content-center">
          <div class="reward-img__wrap w-65">
            <img class="reward-img-paypal border shadow" src="../../../../../assets/image/PayPal.jpg"
              alt="Paypal">
          </div>
        </div>

        <div class="col-12 col-sm-6 col-md-4 mb-2">
          <div class="virtual-mastercard-container d-flex justify-content-center">
            <div class="virtual-mastercard">
              <div class="card-header">
                <div class="logo">TANGO <br> VIRTUAL</div>
                <div class="brand"> <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="45" height="45"
                    viewBox="0 0 52 52" class="svgLogo">
                    <path fill="#ff9800" d="M32 10A14 14 0 1 0 32 38A14 14 0 1 0 32 10Z"></path>
                    <path fill="#d50000" d="M16 10A14 14 0 1 0 16 38A14 14 0 1 0 16 10Z"></path>
                    <path fill="#ff3d00"
                      d="M18,24c0,4.755,2.376,8.95,6,11.48c3.624-2.53,6-6.725,6-11.48s-2.376-8.95-6-11.48 C20.376,15.05,18,19.245,18,24z">
                    </path>
                  </svg></div>
              </div>
              <div class="card-footer">
                <div class="reward-box">Virtual MasterCard</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>


<!-- <app-footer class="privacy-policyfooter"></app-footer> -->
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseService } from './base/service/base.service';
import { userApi } from './base/model/constants/ApiEndPoints';
import { PanelGuId } from './base/model/constants/AppConstants';
import { ToastrService } from 'ngx-toastr';
import { UserService } from './modules/users/services/user.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = 'Unipanel.App.Web';
  constructor(public translate: TranslateService, private _baseService: BaseService, private toastr: ToastrService, private userService:UserService) { }
  getCountryCulture;
  ngOnInit() {
    this.translate.setDefaultLang('en');
    // Method for getCulture
    this._baseService.get(userApi.getCulture + `?panelGuid=${PanelGuId.OpinionEdge}`).then((res: any) => {
      const dataArray = res
      this.getCountryCulture = dataArray.split('-')[0];
      const countryFromCulture = dataArray.split('-')[1];
      this.userService.countryCultureSource.next(this.getCountryCulture);
      this.userService.countryFromCulture.next(countryFromCulture);
      localStorage.setItem('Uculture', this.getCountryCulture)
      this.getCountry(this.getCountryCulture)
    }).catch((res) => {
      // this.toastr.error(res.error.message)
      this.translate.setDefaultLang('en');
    })
    this.translate.addLangs(['en', 'es', 'de', 'ru', 'pt', 'ko', 'cn', 'fr', 'it', 'ja', 'ro','sv', 'id', 'ms', 'ph', 'pl', 'tr', 'ar', 'bg', 'nl', 'th', 'vi']);

  }
  // Method for set defaultLanguage 
  getCountry(countryLanguage) {
    if (this.translate.getLangs().includes(countryLanguage)) {
      this.translate.use(countryLanguage);
    } else {
      this.translate.setDefaultLang('en');        // Fallback to default language if requested language is not available
    }
  }
  switchLanguage(lang: string) {
    this.translate.use(lang);
  }
}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { userApi } from 'src/app/base/model/constants/ApiEndPoints';
import { PanelGuId, PanelId, RecaptchaSiteKey } from 'src/app/base/model/constants/AppConstants';
import { BaseService } from 'src/app/base/service/base.service';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import * as bcrypt from 'bcryptjs';
import { DeviceDetectorService } from 'ngx-device-detector';
var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
import { ZipPattern } from 'src/app/base/model/constants/ZipPattern';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/modules/users/services/user.service';
import { ReCaptcha2Component } from 'ngx-captcha';
@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html'
})
export class CreateAccountComponent implements OnInit {
  constructor(private _baseService: BaseService,
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private elementRef: ElementRef,
    private activeRoute: ActivatedRoute,
    private deviceDetector: DeviceDetectorService,
    private translate: TranslateService,
    private userService: UserService,
  ) {
    this.epicFunction();
  }
  createUserForm: FormGroup;
  getCountryLanguage;
  getIpAddress;
  showDetails = true;
  show = false;
  isShowCreateForm = true;
  isShowSuccessMessage = false;
  showConfirm = false;
  getCountry;
  emailLink;
  selectedOption: string;
  countryId;
  languageId: number;
  selectedLanguage;
  months;
  referralCode;
  affilateid;
  affiliateTrafficId;
  transid;
  campid;
  scampid;
  firstName;
  lastName;
  password;
  zipCode;
  gender;
  dateOfBirth;
  signUpQuestionList = [];
  deviceInfo;
  deviceType;
  isShowDuplicateIP: boolean;
  showMessage;
  notValidCountryMessage: string;
  zipPatternObjects: Array<ZipPattern> = [];
  showZipErrorMessage: boolean = false;
  isSubmitAllowed: boolean = false;
  regexForZip;
  zipValidation = require('../../../../../assets/ZipValidationPatterns.json');
  trafficlogData;
  userCountryId;
  zipErrorMessage;
  zipCountryId;
  trafficId;
  showPasswordRequirements = false;
  isShowLoader: boolean;
  vistorId;
  recaptchaSiteKey = RecaptchaSiteKey.siteKey;
  getCulture;
  statusCodeMap = require('../../../../base/model/SCMapping.json')
  passwordStrength: any;
  showCriteria: boolean;
  hearOpinionEdgeQuestion: any;
  opinionEdgeHearOption: any;
  @ViewChild(ReCaptcha2Component, { static: false }) captchaComponent: ReCaptcha2Component;

  
  ngOnInit() {
    this.getCreateUserForm();
    this.onIpAddress(); // Method for Get IP Address


    // Method for affilate user
    let hashUrl = window.location.hash;
    const decodedUrl = decodeURIComponent(hashUrl)
    const newUrl = decodedUrl.includes('?') ? decodedUrl?.split('?')[1] : decodedUrl?.split('/')[2];
    this.affilateid = this.getQueryParam(newUrl, "affid");
    this.transid = this.getQueryParam(newUrl, "tid");
    this.campid = this.getQueryParam(newUrl, "campid");
    this.scampid = this.getQueryParam(newUrl, "scampid");
    if (this.scampid) {
      sessionStorage.setItem("_affiliateUrl", String(newUrl));
    }

    // Method for get Refercode from Url
    this.activeRoute.params.subscribe(params => {
      const newRefferalCode = params['id'];
      if (sessionStorage.getItem("referralCode")) {
        sessionStorage.setItem("referralCode", newRefferalCode);
        this.referralCode = newRefferalCode
      } else if (sessionStorage.getItem("referralCode") === sessionStorage.getItem("_affiliateUrl")) {
        sessionStorage.setItem("referralCode", newRefferalCode);
        this.referralCode = newRefferalCode;
      }
    });

    // Method for get device type
    const deviceType = this.deviceInfo.os.toLowerCase()

    if (deviceType === 'ios') {
      this.deviceType = 1
    } else if (deviceType === 'android') {
      this.deviceType = 2
    } else {
      this.deviceType = 3
    }
    const affiliateUrl = location.origin + `/#/create-account?affid=${this.affilateid}&tid=${this.transid}&campid=${this.campid}&scampid=${this.scampid}`
    //Method for  Get CountryLanguage
    const body = {
      panelId: PanelId.OpinionEdge,
      panelGuid: PanelGuId.OpinionEdge,
      affId: this.affilateid,
      tId: this.transid,
      campId: this.campid,
      subCampId: this.scampid,
      device: 3,
      affiliateUrl: affiliateUrl
    }
    this._baseService.post(userApi.getCountryLanguage, body).then((res: any) => {
      this.getCountryLanguage = res.data ? res.data : [res];
      this.getSignUpQuestion(this.getCountryLanguage, res.trafficId);
      this.zipCountryId = res.countryId;
      this.trafficId = res.trafficId;
    }).catch((res: any) => {
      const mapStatusCode = this.getMappedStatusCode(res);
      if (mapStatusCode) {
        if (mapStatusCode == '1023') {
          this.isShowCreateForm = false;
          this.translate.get(mapStatusCode).subscribe((translate) => {     // proxy VPN message from backend
            this.showMessage = translate;
          })
          return;
        }
        this.translate.get(mapStatusCode).subscribe((translate) => {     // proxy VPN message from backend
          this.showMessage = translate;
        })
      } else {
        this.translate.get('somethingWentWrong').subscribe((translate) => {   // default message
          this.showMessage = translate;
        })
      }
      this.isShowCreateForm = false;
    })

    if (localStorage.getItem('access_token')) {
      this.router.navigate(['login/body/dashboard'])
    }

    this.userService.countryCultureSource.subscribe(country => {
      this.getCulture = country;
    })
  }

  getCreateUserForm() {
    const emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // Create user form
    this.createUserForm = this.fb.group({
      email: new FormControl('', Validators.compose([
        Validators.email,
        Validators.required,
        Validators.pattern(emailPattern)
      ])),
      firstName: new FormControl('', [Validators.required, this.userService.nameValidator()]),
      lastName: new FormControl('', [Validators.required, this.userService.nameValidator()]),
      getMonth: new FormControl('', Validators.required),
      getYear: new FormControl('', Validators.required),
      password: new FormControl('', Validators.compose([
        Validators.required,
        this.noSpaceValidator,
        Validators.pattern('^(?=.*[A-Za-z])(?=.*[0-9])(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+].{7,}'),
        Validators.minLength(8)
      ])),
      confirmPassword: new FormControl('', Validators.compose([
        Validators.required
      ])),
      zipCode: new FormControl('', [Validators.required]),
      language: new FormControl('', Validators.required),
      gender: new FormControl('', Validators.required),
      opinionEdgeHear: new FormControl(''),
      acceptTerms: new FormControl(false, Validators.compose([
        Validators.requiredTrue
      ])),
      // recaptcha: new FormControl('', Validators.required),
    }, {
      validators: this.passwordMatchValidator.bind(this)
    })
  }

  onIpAddress() {
    this._baseService.get(userApi.ipAddress).then((res: any) => {
      this.getIpAddress = res.IP;
    }).catch((err: any) => {
    })
  }

  signUpArray = [];
  // Method for get signUpQuestion
  async getSignUpQuestion(country, trafficId) {
    const bodyRequest = this.signUpQsnBodyRequest(country, trafficId);
    try {
      const res: any = await this._baseService.post(userApi.getSignupQsnViaLanguage, bodyRequest);
      this.getSignUpQsnResponse(res);
    } catch (err) {
      this.handleSignUpQsnError(err);
    }
  }

  getSignUpQsnResponse(res: any) {
    this.signUpArray = [res];

    const standardQusn = res.signupQsn.filter((e: any) => e.qsnShortForm === 'STANDARD_ Discover_Opinion-Edge');
    if (standardQusn.length > 0) {
      this.createUserForm.get('opinionEdgeHear').addValidators(Validators.required)
      this.createUserForm.get('opinionEdgeHear').updateValueAndValidity()
    }
    this.hearOpinionEdgeQuestion = standardQusn[0]?.qsnStatement;
    this.opinionEdgeHearOption = standardQusn[0]?.option;

    this.gender = res.signupQsn.find((e: any) => e.qsnShortForm === 'GENDER');
  }

  handleSignUpQsnError(err: any) {
    this.isShowCreateForm = false;
    const mappedStatusCode = this.getMappedStatusCode(err);

    if (mappedStatusCode) {
      this.translate.get(mappedStatusCode).subscribe((translatedMessage: any) => {
        this.showMessage = translatedMessage;
      });
    } else {
      this.translate.get('somethingWentWrong').subscribe((translate) => {
        this.showMessage = translate;
      });
    }
  }

  getMappedStatusCode(err: any) {
    return err.statusCode ? this.statusCodeMap[0][err.statusCode] : null;
  }

  signUpQsnBodyRequest(country: any[], trafficId: string) {
    return {
      option: [
        {
          countryId: country[0].countryId,
          languageId: country[0].languageId,
          language: country[0].countryLanguage,
          isSelected: true
        }
      ],
      panelGuid: PanelGuId.OpinionEdge,
      countryId: country[0].countryId,
      trafficId: trafficId
    };
  }

  // Method for get selected languageId & countryId
  onLanguageSelect(event) {
    const value = event.target.value
    const countryId = this.getCountryLanguage.find(item => item.countryLanguage === value)
    this.countryId = countryId.countryId
    this.languageId = countryId.languageId
    this.affiliateTrafficId = countryId.affiliateTrafficId;
  }

  // Method for remove touched class
  onInput() {
    const myInputControl = this.createUserForm.get('email');
    if (myInputControl) { }
  }
  // Method for create user
  async onCreateUser() {
    this.clearVisitorData();
    await this.getVisitor();
    this.markFormGroupTouched(this.createUserForm);

    const formValues = this.createUserForm.value;
    const month = this.formatMonth(formValues.getMonth);
    const recaptchaToken = formValues.recaptcha;

    const body = this.buildRequestBody(formValues, month);
    this.updateSignUpQuestions(formValues, month);

    if (this.createUserForm.invalid) {
      return this.handleInvalidForm();
    }

    if (this.isSubmitAllowed) {
      this.submitUserCreation(body, recaptchaToken);
    } else {
      this.handleZipError();
    }
  }

  clearVisitorData() {
    localStorage.removeItem('visitorId');
  }

  formatMonth(month: number): string {
    return month < 10 ? `0${month}` : month.toString();
  }

  buildRequestBody(formValues: any, month: string) {
    const body: any = {
      panelGuid: PanelGuId.OpinionEdge,
      email: formValues.email,
      password: bcrypt.hashSync(formValues.password),
      language: formValues.language,
      ipAddress: this.getIpAddress,
      countryId: this.countryId,
      referral: this.referralCode,
      fcmToken: null,
      languageId: this.languageId,
      signupQsn: this.signUpArray[0].signupQsn,
      affiliateTrafficId: this.affiliateTrafficId,
      appType: 10,
      device: 3,
      trafficId: this.trafficId,
      visitorId: this.vistorId || undefined
    };
    if (body.visitorId === '' || body.visitorId === null || body.visitorId === undefined) {
      delete body.visitorId;
    }
    return body;
  }

  updateSignUpQuestions(formValues: any, month: string) {
    this.signUpArray[0].signupQsn.forEach((element: any) => {
      if (element.qsnShortForm === 'Full Name') {
        element['firstName'] = formValues.firstName;
        element['lastName'] = formValues.lastName;
      }
      if (element.qsnShortForm === 'Zip') {
        element['answerKey'] = formValues.zipCode.replace(/\s/g, "").toUpperCase();
      }
      if (element.qsnShortForm === "DOB") {
        element['answerKey'] = `${formValues.getYear}-${month}-01`;
      }
      if (element.qsnShortForm === "STANDARD_ Discover_Opinion-Edge") {
        element.option.forEach(optionItem => {
          optionItem.isSelected = optionItem.optionStatement === formValues.opinionEdgeHear;
        });
      }
    });
  }

  handleInvalidForm() {
    if (this.createUserForm.get('acceptTerms').invalid) {
      this.translate.get('acceptTermsMessage').subscribe((data) => {
        this.toastr.error(data);
      });
    }
  }

  submitUserCreation(body: any, recaptchaToken: string) {
    this.isShowLoader = true;
    const reCaptchaTokenBody = { token: recaptchaToken };

    this._baseService.post(userApi.createUser, body).then((res: any) => {
      this.handleSuccessResponse(res);
    }).catch((err) => {
      this.handleErrorResponse(err);
    });
  }

  handleSuccessResponse(res: any) {
    this.translate.get('userCreate').subscribe((translate) => {
      this.toastr.success(translate, '', { toastClass: 'success-toast', timeOut: 3000 });
    });
    this.emailLink = res.data[0].email;
    this.isShowSuccessMessage = true;
    this.isShowCreateForm = false;
    localStorage.removeItem("_affiliateUrl");
    localStorage.removeItem("referralCode");
  }

  handleErrorResponse(err: any) {
    this.isShowLoader = false;
    if (!err.statusCode) {
      this.toastr.error(this.translate.instant('somethingWentWrong'));
      return;
    }
    const mappedStatusCode = this.statusCodeMap[0][err.statusCode];
    if (err.statusCode == '1012') {
      this.isShowCreateForm = false;
      this.translate.get(mappedStatusCode).subscribe((translate) => {
        this.showMessage = translate;
      });
      return;
    }
    this.translate.get(mappedStatusCode).subscribe((translate) => {
      this.toastr.error(translate);
    });
  }

  handleZipError() {
    if (this.zipPatternObjects.length > 0) {
      this.translate.get('zipValidInfoMessage').subscribe((translate) => {
        this.toastr.error(translate + this.zipPatternObjects[0].message);
      });
    }
  }


  onSelectGender(event) {
    const value = event.target.value;
    this.signUpArray[0].signupQsn.forEach(item => {
      item.option.forEach(element => {
        element.isSelected = (element.optionStatement === value);
      });
    });


  }
  // Method for matchPassword 
  passwordMatchValidator(formGroup: FormGroup) {
    const password = formGroup.get('password').value;
    const confirmPassword = formGroup.get('confirmPassword').value;
    return password === confirmPassword ? null : { passwordMismatch: true };
  }

  // method for not allowed alphabets 
  onInputKeyDown(event: KeyboardEvent) {
    const inputKey = event.key;
    const isAlphabet = /^[a-zA-Z]$/.test(inputKey);

    if (isAlphabet) {
      event.preventDefault();
    }
  }
  // Methor for show/hide password
  openPassword() {
    this.show = !this.show;
  }

  // Methor for show/hide Confirm password
  openConfirmPassword() {
    this.showConfirm = !this.showConfirm
  }

  // Methor for getMonths
  getMonths(): string[] {
    const datePipe = new DatePipe('en-US');
    const months = [];
    for (let i = 0; i < 12; i++) {
      const monthDate = new Date(2000, i, 1); // year and day don't matter
      const monthName = datePipe.transform(monthDate, 'MMMM');
      const translatedMonthName = this.translate.instant(`MONTHS.${monthName}`);
      months.push(translatedMonthName);
    }
    return months;
  }

  // Methor for getYears
  getYears(): number[] {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = currentYear - 13; i >= currentYear - 90; i--) {
      years.push(i);
    }
    return years;
  }

  // Method for get params data
  getQueryParam(url, param) {
    var params = new URLSearchParams(url);
    return params.get(param);
  }

  validateInputText(event: KeyboardEvent) {
    const inputChar = String.fromCharCode(event.keyCode);
    const pattern = /[A-Za-z\s]/;
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  onZipValidation(event: any) {
    this.zipPatternObjects = this.zipValidation.filter(e => e.countryids.find(l => l == this.zipCountryId));
    this.regexForZip = new RegExp(this.zipPatternObjects[0].patterns[0]);
    const inputValue = event.target.value.replace(/\s/g, "").toUpperCase()
    this.showZipErrorMessage = !this.regexForZip.test(inputValue);
    this.isSubmitAllowed = !this.showZipErrorMessage;
  }

  // Method for get device data
  epicFunction() {
    this.deviceInfo = this.deviceDetector.getDeviceInfo();
  }

  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    })
  }

  noSpaceValidator(control: AbstractControl): ValidationErrors | null {
    if (/\s/.test(control.value)) {
      return { noSpace: true };
    } else {
      return null;
    }
  }

  reloadCaptcha(): void {
    this.captchaComponent.reloadCaptcha();
  }

  async getVisitor() {
    this.vistorId = await this.userService.getVistorId();
  }

  checkPasswordStrength(event: any) {
    const password = event.target.value;
    this.userService.checkPasswordStrength(password);
    this.passwordStrength = {
      hasLetter: this.userService.hasLetter,
      hasNumber: this.userService.hasNumber,
      hasSpecialChar: this.userService.hasSpecialChar,
      hasMinLength: this.userService.hasMinLength,
      hasSpace: this.userService.hasSpace
    };
  }
}

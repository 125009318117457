<div class="container-fluid">
  <div class="row fixed-topheader">
    <div class="row sticky-top">
      <div class="d-flex justify-content-between dashboard-mainboady">
        <div class="dashboard-heading">
          <h3>{{'redeemPoints'|translate}}</h3>
        </div>
        <div class="dashboard-headingicons">
          <app-header></app-header>
        </div>
      </div>
      <hr class="redeem-dashboardhr-line" />

    </div>
  </div>
  <!-- page parent div -->
  <div class="row fixed-body">
    <!-- user validdation div -->
    <div class="container" *ngIf="!isShowRedeemDiv">
      <div class="row verification-div">
        <!-- password validation div -->
        <div class="col-md-5" *ngIf="isShowVerified === 'verified'">
          <div class="p-3 validation-div">
            <div class="row mb-2">
              <span class="fs-6">{{'validatePassword'|translate}}</span>
            </div>
            <div class="rd-enterpassowrd-div">
              <div class="input-group mb-3 txt-group">
                <input type="password" [type]="!show ? 'password' : 'text'" [placeholder]="'password'|translate"
                  class="form-control revalidation-password" id="txtPassword" autocomplete="off"
                  [(ngModel)]="passwordValue" (keyup.enter)="showRedeemPage()">
                <span class="cursor-pointer" (click)="openPassword()" *ngIf="!show"><i
                    class="fa-regular fa-eye-slash"></i></span>
                <span class="cursor-pointer" (click)="openPassword()" *ngIf="show"><i
                    class="fa-sharp fa-regular fa-eye"></i></span>
              </div>
              <div class="mb-3 text-center">
                <button class="btn btn-primary btn-sm" (click)="showRedeemPage()"
                  [disabled]="!passwordValue">{{'submit'|translate}}</button>
              </div>
            </div>
          </div>
        </div>

        <!-- email unverify div -->
        <div class="col-md-5" *ngIf="isShowVerified === 'notVerified'">
          <div class="rd-enterpassowrd-div validation-div mb-3">
            <span class="text-primary d-flex justify-content-center align-center py-2 px-2">
              <i class="fa-sharp fa-solid fa-triangle-exclamation mx-1"></i>
              {{'alert'|translate}}
            </span>
            <div class="mb-3 text-secondary p-3 fs-6">
              {{'unVerifyAlertMessage'|translate}}
            </div>
            <div class="mb-3 text-center">
              <button class="btn btn-primary btn-sm" (click)="verifyEmail()">{{'resendVerifyEMail'|translate}}</button>
            </div>
          </div>
        </div>

        <div class="col-md-5" *ngIf="isShowVerified === 'w9NotVerified'">
          <div class="p-3 validation-div">
            <div class="rd-enterpassowrd-div">
              <span class="text-primary d-flex justify-content-center align-center py-2 px-2">
                <i class="fa-sharp fa-solid fa-triangle-exclamation mx-1"></i>
                {{'attention'|translate}}
              </span>
              <div class="mb-3 text-secondary p-2 fs-8">
                Please fill out the W9 details and upload the form to proceed with your next redemption. It's mandatory
                for compliance. Once done, you can proceed with your redemption.
              </div>
              <div class="mb-3 text-center">
                <button class="btn btn-primary btn-sm w-25" (click)="redirectW9Tab()">Next</button>
              </div>
            </div>
          </div>
        </div>

        <!-- loader -->
        <div class="col-md-5" *ngIf="isShowLoader">
          <div class="redeemverify-loader">
            <div class="loader">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>

        <!-- user levels section  -->
        <section class="col-md-7 px-3 redemption-rule-card">
          <div class="row pt-1 user-level-tophed">
            <div class="level-info">
              <div class="user-level-hed text-primary fw-bold">
                {{'userLevel'|translate}}
              </div>
            </div>
          </div>
          <div class="level-box-container">
            <div class="row">
              <div class="col-md-6 mb-2">
                <div
                  [ngClass]="userLevel == 'Level 1' ? 'card rd-level-card mb-0 currentUserLevel':'card mb-0 rd-level-card'">
                  <div class="d-flex">
                    <div>
                      <!-- <img src="../assets/icons/user-level-icon.png" alt=""> -->
                    </div>
                    <div class="fs-7 p-2">
                      <div class="d-flex justify-content-between">
                        <div class="fw-bold">{{'level1'|translate}}</div>
                        <div class="px-1" placement="top" [ngbTooltip]="'levelRule1' | translate">
                          <span class="i-icon">
                            <span>i</span>
                          </span>
                        </div>
                      </div>
                      <!-- <div class="text-dark mt-2">{{'redeemCondition1'|translate}}</div> -->
                      <div class="text-dark mt-2"> {{'redemptionEligibility'|translate}} :<strong>  1500 {{'points'| translate | lowercase}} </strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-2">
                <div
                  [ngClass]="userLevel == 'Level 2' ? 'card rd-level-card mb-0 currentUserLevel':'card mb-0 rd-level-card'">
                  <div class="d-flex">
                    <div>
                      <!-- <img src="../assets/icons/user-level-icon.png" alt=""> -->
                    </div>
                    <div class="fs-7 p-2">
                      <div class="d-flex justify-content-between">
                        <div class="fw-bold">{{'level2'|translate}}</div>
                        <div class="px-1" [ngbTooltip]="'levelRule2' | translate">
                          <span class="i-icon">
                            <span>i</span>
                          </span>
                        </div>
                      </div>
                      <!-- <div class="text-dark mt-2">{{'redeemCondition1'|translate}}</div> -->
                      <div class="text-dark mt-2"> {{'redemptionEligibility'|translate}} :<strong> 1000 {{'points'| translate | lowercase}} </strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">

              <div class="col-md-6 mb-2">
                <div
                  [ngClass]="userLevel== 'Level 3' ? 'card rd-level-card mb-0 currentUserLevel':'card mb-0 rd-level-card'">
                  <div class="d-flex">
                    <div>
                      <!-- <img src="../assets/icons/user-level-icon.png" alt=""> -->
                    </div>
                    <div class="fs-7 p-2">
                      <div class="d-flex justify-content-between">
                        <div class="fw-bold">{{'level3'|translate}}</div>
                        <div class="px-1" placement="top" [ngbTooltip]="'levelRule3' | translate">
                          <span class="i-icon">
                            <span>i</span>
                          </span>
                        </div>
                      </div>
                      <!-- <div class="text-dark mt-2">{{'redeemCondition1'|translate}}</div> -->
                      <div class="text-dark mt-2"> {{'redemptionEligibility'|translate}} :<strong> 500 {{'points'| translate | lowercase}} </strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-2">
                <div
                  [ngClass]="userLevel== 'Level 4' ? 'card rd-level-card mb-0 currentUserLevel':'card mb-0 rd-level-card'">
                  <div class="d-flex">
                    <div>
                      <!-- <img src="../assets/icons/user-level-icon.png" alt=""> -->
                    </div>
                    <div class="fs-7 p-2">
                      <div class="d-flex justify-content-between">
                        <div class="fw-bold">{{'level4'|translate}}</div>
                        <div class="px-1" [ngbTooltip]="'levelRule4' | translate">
                          <span class="i-icon">
                            <span>i</span>
                          </span>
                        </div>
                      </div>
                      <!-- <div class="text-dark mt-2">{{'redeemCondition1'|translate}}</div> -->
                      <div class="text-dark mt-2"> {{'redemptionEligibility'|translate}} :<strong> 100 {{'points'| translate | lowercase}} </strong>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </section>
      </div>

      <div class="row mt-4 mt-2">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 mb-3">
              <h4 class="panel-text-header text-center">{{"rewardHeader"|translate}}
              </h4>
            </div>
            <div class="col-12 col-sm-6 col-md-4 mb-3 d-flex justify-content-center">
              <div class="reward-img__wrap w-75">
                <img class="reward-img tango-img rounded shadow" src="../../../../../assets/image/Tango_reward.jpg"
                  alt="Reward Link India">
                  <div class="reward-flag" *ngIf="countryCode">
                    <div class="">{{countryCode.toUpperCase()}}</div>
                    <div>
                      <span class="{{'fi fi-'+ countryCode}}"></span>
                    </div>
                  </div>
              </div>
              
            </div>
            <div class="col-12 col-sm-6 col-md-4 mb-3 d-flex justify-content-center">
              <div class="reward-img__wrap w-75">
                <img class="reward-img-paypal border shadow" src="../../../../../assets/image/PayPal.jpg" alt="Paypal">
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 mb-3">
              <div class="virtual-mastercard-container d-flex justify-content-center">
                <div class="virtual-mastercard w-75">
                  <div class="card-header">
                    <div class="logo">TANGO <br> VIRTUAL</div>
                      <div class="brand"> <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="45" height="45"
                        viewBox="0 0 52 52" class="svgLogo">
                        <path fill="#ff9800" d="M32 10A14 14 0 1 0 32 38A14 14 0 1 0 32 10Z"></path>
                        <path fill="#d50000" d="M16 10A14 14 0 1 0 16 38A14 14 0 1 0 16 10Z"></path>
                        <path fill="#ff3d00"
                          d="M18,24c0,4.755,2.376,8.95,6,11.48c3.624-2.53,6-6.725,6-11.48s-2.376-8.95-6-11.48 C20.376,15.05,18,19.245,18,24z">
                        </path>
                      </svg></div>
                  </div>
                  <div class="card-footer">
                    <div class="reward-box">Virtual MasterCard</div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="row rd-level-parent w-100">
        <div class="row mt-4">
          <div class="level-info">
            <div class="user-level-hed text-primary fw-bold">
              {{'guidelines'|translate}}
            </div>
          </div>
        </div>

        <div class="row">
          <div class="row mt-1 fs-8 px-2">
            <ol class="rd-list-group">
              <li>{{'guidelines1'|translate}}</li>
              <li>{{'guidelines2'|translate}}</li>
              <li>{{'guidelines3'|translate}}</li>
            </ol>
          </div>
        </div>
      </div>
    </div>

    <!--main Redemption div -->
    <div class="row redeem-point" *ngIf="isShowRedeemDiv">
      <div>
        <div class="row">
          <div class="col-md-12 mb-2">
            <div class="row">
              <!-- <h3 class="redeem-header">{{'points'|translate}}</h3> -->
              <div class="row">
                <div class="col-md-4">
                  <div class="card">
                    <div class="text-primary fw-bold py-1 fs-4">{{totalPoint}}</div>
                    <small>{{'total'|translate}} {{'points'|translate}}</small>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="card">
                    <div class="text-primary fw-bold py-1 fs-4">{{redeemPoint}}</div>
                    <small>{{'redeemed'|translate}} {{'points'|translate}}</small>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="card">
                    <div
                      [ngClass]="minRedeemPoint > availablePoints ?'text-primary fw-bold fs-5':'text-primary fw-bold py-1 fs-4'"
                      class="text-primary fw-bold fs-5">{{availablePoints}}</div>
                    <small>{{'balancePoints'|translate}}</small>
                    <div class="redeem-note d-flex align-center" *ngIf="minRedeemPoint > availablePoints">
                      <strong class="text-primary fw-bold">{{minRedeemPoint - availablePoints}}</strong>
                      <div class="px-1">{{'pointRemaining'|translate}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="col-md-7 mb-2"> -->
          <!-- <h3 class="redeem-header">{{'amount'|translate}}</h3>
            <div class="d-flex">
              <div>
                <div class="r-total">
                  <div class="border px-3 bg-rigncolor text-dark rounded">USD</div>
                </div>
                <div class="r-total">
                  <div class="border px-3 bg-rigncolor text-dark rounded">{{currencyCode}}</div>
                </div>
              </div>
              <div class="points-summary text-left">
                <div class="r-point"> {{conversionAmount}}</div>
                <div class="r-point"> {{availableAmount}}</div>
              </div>
            </div>
            <div class="text-primary fs-7 mt-1 mx-1">
              <span>{{'1USD'|translate}}</span>
              <span class="mx-2">=</span>
              <span>{{'points_point'|translate}}</span>
            </div> -->
          <!-- </div> -->
        </div>
        <div class="row">
          <div class="row personal-detailtab pb-0">
            <ul class="nav">
              <li class="nav-item m-0 mb-3" (click)="redemptionOptions()"
                [ngClass]="!Active? 'personaldetails':'user-profile'">
                <a>{{'redemptionOptions'|translate}}</a>
              </li>
              <li class="nav-item" (click)="redemptionDetails()" [ngClass]="!Active? 'user-profile':'personaldetails'">
                <a>{{'redemptionDetails'|translate}}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- start redemption option -->
      <div class="row redeem-cardgroup" [ngClass]="!isShowError ? '':'error-redeemDailog'" id="redemptionOption"
        *ngIf="!Active">
        <div [class.disabled-redeem-input]="!activeCard" class="col-md-3">
          <!-- selected reward  -->
          <div [attr.aria-disabled]="!activeCard ? 'false' : 'true'">
            <form [formGroup]="redeemRequestForm" (ngSubmit)="onRedeemRequest()">
              <!-- <h3 class="redeems-header">{{'selectedReward'|translate}}</h3> -->
              <div class="card  redeem-card cursor-unset selected-card">
                <img src="../../../../../assets/image/selectedpaypal.jpg" *ngIf="isPaypalEnable" class="paypla-selected-image w-100" alt="" alt="">
                <img src="../../../../../assets/image/tango.png" *ngIf="!isPaypalEnable" class="card-image" alt="">
                <div>
                  <div class="card notselected-card mb-2">
                    <div class="card-center text-center text-light" *ngIf="!activeCard">{{'noRewardSelected'|translate}}
                    </div>
                    <div class="card-center text-center text-light" *ngIf="activeCard && !isPaypalEnable">{{redemptionCardTitle}}</div>
                  </div>
                </div>
                <!-- <div class="card-footer selected-card-footer px-0 pt-2">{{redemptionCardTitle}}</div> -->
                <div class="text-light fs-6 py-1">{{'balance'|translate}} {{'amount'|translate}}</div>

                <div class="d-flex justify-content-between text-light w-100 fs-6">
                  <div class="USD">
                    <h6 class="text-center fs-6">{{conversionAmount}}</h6>
                    <div class="r-total">
                      <div class="border px-3 bg-rigncolor text-light rounded">USD</div>
                    </div>
                  </div>
                  <div class="vr"></div>
                  <div class="other-currency">
                    <h6 class="text-center fs-6">{{availableAmount}}</h6>
                    <div class="r-total">
                      <div class="border px-3 bg-rigncolor text-light rounded">{{currencyCode}}</div>
                    </div>
                  </div>
                </div>
                <small class="redeem-calc-text">
                  <div class="text-light mt-1 mx-1">
                    <span>{{'1USD'|translate}}</span>
                    <span class="mx-2">=</span>
                    <span>{{'points_point'|translate}}</span>
                  </div>
                </small>
                <div *ngIf="Variable_Value">
                  <div class="card-footer selected-card-footer px-0 pt-2">
                    <div class="redeem-note">{{'redeemNote' | translate}} <strong> {{currencyCode}} </strong>
                      <strong>{{maxRedeemValue}}</strong>
                    </div>
                    <input [placeholder]="'amount'|translate" formControlName="getValue" id="txtAmount" type="text"
                      class="form-control p-1 mt-2" (keypress)="validationInput($event)" (paste)="(false)">
                  </div>
                </div>

                <div class="w-75" *ngIf="fixed_Value">
                  <select class="form-select form-select-md cursor-pointer" formControlName="getValue"
                    [(ngModel)]="selectedValue">
                    <option [ngValue]="item" *ngFor="let item of redemptionItem">
                      {{cardCurencySymbol()}}{{item.faceValue}}
                    </option>
                  </select>
                </div>

                <div *ngIf="Both_Value">
                  <div class="card-footer selected-card-footer px-0 pt-1">
                    <div class="redeem-note">{{'redeemNote' | translate}} <strong> {{currencyCode}} </strong>
                      <strong>{{maxRedeemValue}}</strong>
                    </div>
                    <input [placeholder]="'amount'|translate" formControlName="getValue" id="txtAmount" type="text"
                      #inputField class="form-control p-1 mt-2" (keypress)="validationInput($event)" (paste)="(false)">
                  </div>
                  <div class="text-light text-center mb-1">OR</div>
                  <select class="form-select form-select-md cursor-pointer" formControlName="getValue"
                    (change)="onDropdownChange()" [(ngModel)]="selectedValue">
                    <option [ngValue]="item" *ngFor="let item of redemptionItem">
                      {{cardCurencySymbol()}}{{item.faceValue}}
                    </option>
                  </select>
                </div>
              </div>
              <small class="row px-3 pb-2">
                <span class="p-0"> {{countryBaseMessage}} </span>
              </small>
              <div class="row mx-0  mb-2 align-center redeemBtn-info"
                [ngbTooltip]="availablePoints < minRedeemPoint ? getRedeemInfoText() : ''">
                <button class="btn btn-primary" type="submit"
                  [disabled]="redeemRequestForm.invalid || availablePoints < minRedeemPoint">{{'redeem'|translate}}
                </button>
                <!-- <button type="button" class="btn btn-outline-secondary mt-2" (click)="removeMessage()">Cancel</button> -->
              </div>
            </form>
          </div>
        </div>

        <!-- catalog -->
        <div class="col-md-9">
          <div class="row" [ngClass]="isPaypalEnable ? 'tango-enable':''">
            <!-- show reward cards  not selected-->
            <div class="card-container">
              <ng-container *ngFor="let item of redemptionOption;">
                <div [ngbTooltip]="!activeCard ? ('selectYourCard' | translate) : ''">
                  <div class="card redeem-card" (click)="selectReward(item)">
                    <img src="{{item.imageUrls['200w-326ppi']}}" class="card-image" alt="">
                    <div class="card-footer redeem-note">{{item.brandName}}</div>
                  </div>
                </div>
              </ng-container>
              <ng-container>
                <div class="reward-paypal-disable" [ngbTooltip]="'comingSoon' | translate">
                  <div class="card redeem-card">
                    <img class="card-image reward-paypal-rd" src="../../../../../assets/image/PayPal.jpg" alt="Paypal">
                    <!-- <div class="card-footer redeem-note">Paypal</div> -->
                    <div class="card-footer redeem-note">{{"comingSoon"|translate}}</div>
                  </div>
                </div>
              </ng-container>
            </div>
            <section *ngIf="!isPaypalEnable" [ngClass]="activeCard ? 'redeem-options questions':'border-0'">
              <div class="fs-6 mb-2 text-secondary" *ngIf="isShowCatelog">
                {{'viewRewardWeb'|translate}} {{tangoBrandCountries}} {{'checkGiftCard'|translate}}
              </div>
              <!-- show catelog product -->
              <div [ngClass]="isShowCatelog?'row show-catelog':''">
                <div class="card-container tango-catelog" *ngIf="rewardCatelog">
                  <div *ngFor="let item of rewardCatelog">
                    <div class="card redeem-card p-1 mb-2">
                      <img src="{{item.imageUrls['200w-326ppi']}}" class="card-image" alt="">
                      <div class="card-footer show-catelog-footer p-0 pt-2">{{item.brandName}}</div>
                    </div>
                  </div>
                </div>
                <div class="text-center" *ngIf="isShowCatelogLoader">
                  <div class="redeemverify-loader">
                    <div class="loader">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                </div>
                <div class="text-center text-secondary" *ngIf="isShowCatelog">
                  {{emptyCatelogMessage}}
                </div>
              </div>
            </section>
          </div>
          <div class="d-flex justify-content-center" *ngIf="isRedemptionLoader">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <div class="text-center text-secondary" *ngIf="isEmptyRedemption">
            {{emptyRedemptionCard}}
          </div>
        </div>
        <hr>
        <div class="row">
          <strong class="fs-6 mt-3 mb-2 text-secondary">{{'rewardDisclaimer'|translate}}</strong>
          <span class="fs-6 text-secondary">{{'tangoCardRule1'|translate}}<a
              href="https://www.rewardsgenius.com/reward-link-terms-of-service">https://www.rewardsgenius.com/reward-link-terms-of-service.</a>
            {{'tangoCardRule2'|translate}}</span>
        </div>
      </div>

      <div [ngClass]="isShowError ?'div-error-redeemDailog':''" *ngIf="isShowError">
        <dialog open>{{'somethingWentWrong' | translate}}</dialog>
      </div>
      <!-- end redemption option -->
      <div class="row" id="redemptionDetail" *ngIf="Active">
        <section>
          <div class="row">
            <div class="dashboardbody">
              <h3>{{'redemptionDetails'|translate}}</h3>
            </div>
          </div>
          <div class="row dashboard-table table-responsive">
            <table class="table mb-4">
              <thead class="table-heading white-space-nowrap">
                <tr>
                  <!-- <th>{{'requestID'|translate}}</th> -->
                  <th>{{'redemptionType'|translate}}</th>
                  <th>{{'points'|translate}}</th>
                  <th>{{'amount'|translate}}</th>
                  <th>{{'currency'|translate}}</th>
                  <th>{{'status'|translate}}</th>
                  <th>{{('request'|translate) + ' ' + ('date'|translate )}} </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of panelistRedeemHistory">
                  <!-- <td>{{item.id}}</td> -->
                  <td>{{item.rewardName}}</td>
                  <td>{{item.point}}</td>
                  <td>{{item.amount}}</td>
                  <td>{{item.currencyCode}}</td>
                  <td>{{item.status|translate}}</td>
                  <td>{{item.formattedDate}}</td>
                </tr>
              </tbody>
            </table>
            <div class="text-center text-secondary" *ngIf="showRedeemMessage">
              {{'emptyRedeemMessage'|translate}}
            </div>
            <hr />
            <div class="row dashboard-table-pagination">

            </div>
            <!-- <div class="row mt-3 mb-3">
                <span class="show-all-survey">
                  <a href="#">{{'referEarn'|translate}} </a>
                </span>
              </div> -->
          </div>

        </section>
      </div>
    </div>
  </div>
  <div class="page-footer"></div>

</div>
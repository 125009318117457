import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { FingerprintjsProAngularService } from '@fingerprintjs/fingerprintjs-pro-angular';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { userApi } from 'src/app/base/model/constants/ApiEndPoints';
import { BaseService } from 'src/app/base/service/base.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private _baseService: BaseService,
    private fingerprintService: FingerprintjsProAngularService,
    private translate: TranslateService
  ) { 
    this.resetFlags();
  }

  sendAvatar = new Subject;
  userProfile= new Subject;
  sendProfile= new Subject;
  sendPoints = new Subject;
  sendUserProfile = new Subject;
  selectedTab: string = 'myProfile';
  sendUserLevel = new Subject;
  sendCountryCode = new Subject;
  sendUserminPoint = new Subject; 
  callUserAPI =  new Subject();
  countryCultureSource = new Subject();
  countryFromCulture = new Subject();
  hasLetter: boolean;
  hasNumber: boolean;
  hasSpecialChar: boolean;
  hasMinLength: boolean;
  hasSpace: boolean;
  getuserTotalPoint() {
    const panelistId = localStorage.getItem('panelistId');
    this._baseService.get(userApi.getPanelistPoints).then((res: any) => {
      this.sendPoints.next(res?.totalPoints);
      this.sendUserLevel.next(res?.levelName)
      this.sendCountryCode.next(res?.countryCode);
      this.sendUserminPoint.next(res?.minPoint)
      this.callUserAPI.next(true)
    }).catch((res:any) => {
      // Handle error
})
  }

  async getVistorId():Promise<any> {
    let isVisitorId = localStorage.getItem('visitorId')
    if (!isVisitorId || isVisitorId == 'null' || isVisitorId == 'undefined' || !isNaN(JSON.parse(JSON.stringify(isVisitorId)))) {
      try {
        const data = await this.fingerprintService.getVisitorData();
        const visitorId = data.visitorId;
        if (visitorId == null || visitorId == undefined) return null;
        localStorage.setItem('visitorId', visitorId);
        return isVisitorId = visitorId;
      } catch (error) {
        console.log('Something went wrong:', error);
        return null;
      }
    } else {
      return isVisitorId;
    }
  }

  // Function to format the date with translated month
  formatDate(date: Date): string {
    const translatedMonth = this.translate.instant('MONTHS.' + date.toLocaleString('en-us', { month: 'long' }));
    const day = date.getDate();
    const year = date.getFullYear();
    return `${translatedMonth} ${day} ${year}`;
  }


  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    })
  }

  nameValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) {
        return null; // Don't validate if the value is empty
      }
  
      // Check if the value contains only spaces
      if (/^\s*$/.test(value)) {
        return { invalidName: 'Name cannot be only spaces' };
      }
  
      return null; // Validation passed
    };
  }

    // method for send  verification email
    verifyEmail(email) {
      const body = {
        email: email
      }
     return this._baseService.post(userApi.VerificationEmail, body)
    }
  
    checkPasswordStrength(password: string): void {
      const letterPattern = /[A-Za-z]/;
      const numberPattern = /[0-9]/;
      const specialCharPattern = /[!@#$%^&*()_+]/;
      const spacePattern = /\s/;
      const minLength = 8;
  
      this.hasLetter = letterPattern.test(password);
      this.hasNumber = numberPattern.test(password);
      this.hasSpecialChar = specialCharPattern.test(password);
      this.hasMinLength = password.length >= minLength;
      this.hasSpace = spacePattern.test(password);
    }
  
    private resetFlags(): void {
      this.hasLetter = false;
      this.hasNumber = false;
      this.hasSpecialChar = false;
      this.hasMinLength = false;
      this.hasSpace = false;
    }

    getSurveyCheckAPI() {
      return this._baseService.get(userApi.getSurveyCheck)
    }


    debounce(func: Function, wait: number) {
      let timeout: any;
      return (...args: any[]) => {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(context, args), wait);
      };
    }
}

export const userApi = {


    login: '/api/user/loginPanelist',
    ipAddress: '/api/user/getClientIp',
    notificationByPanelistId: '/api/user/getPanelistNotificationByPanelistId',
    getUserDetail:'/api/user/getUserDetail',
    // createUser: '/api/panelist/createPanelistv1',
    checkFormSubmitted:"/api/panelist/checkFormSubmitted",
    formUpload:"/api/panelist/formUpload",
    createUser:'/api/user/createPanelist',
    verifyForgotPassword:'/api/user/verifyForgotPassword',
    forgotPassword: '/api/panelist/forgotPassword',
    resetPassword:'/api/user/resetPassword',
    verifyEmail:'/api/user/verifyEmail',
    referaFriend:'/api/panelist/referafreind',
    getSurveyCheck:'/api/panelist/getSurveyCheck',
    saveAffiliate:'/api/user/saveAffiliate',
    getAndUpdateAffiliateTraffic:'/api/user/getAndUpdateAffiliateTraffic',
    createRedeemRequest: '/api/user/createPanelistRedemRequest',
    creatUserSurveyParticipation: '/api/user/creatUserSurveyParticipation',
    updateUserSurveyParticipation: '/api/user/updateUserSurveyParticipation',
    updatePanelistProfile: '/api/user/updatePanelistProfile',
    saveUserProfileResponse: '/api/user/saveUserProfileResponse',
    uploadUserProfileImage: '/api/dummyUser/uploadUserProfileImage',
    supportTicket: '/api/user/supportTicketRaise',
    changePassword: '/api/user/changePassword',
    extLogger:'/api/company/extLogger',
    verifyReCaptcha:'/api/panelist/verify-captcha',
    readNotification:'/api/user/readNotification',
    VerificationEmail: '/api/user/sendVerificationEmail',
    deleteAccount:'/api/user/blockUnblockPanelist',
    unsubscribeLanding:'/api/panelist/unsubscribeLanding',
    unsubscribeEmail:'/api/panelist/unsubscribeEmail',
    refreshtokenauth:'/api/user/refreshtokenauth',
    getCatalogProduct:'/api/User/getCatalogProduct',
    // trafficlog:'/api/panelist/trafficlog',
    surveyLanding:'/api/panelist/surveyLanding',
    surveyClick:'/api/panelist/surveyClick',
    getDynamicLink:'/api/panelist/getDynamicLink',
    getSurveyForUser: '/api/user/getSurveyForUser',
    getUserProfile: '/api/user/getUserProfile',
    getCatalog: '/api/user/getCatalog',
    getSignupQsnViaLanguage:'/api/User/getSignupQsnViaLanguage',
    getCulture:'/api/company/getCulture',
    getPanelCountry: '/api/systemUser/getPanelCountry',
    getSurveyHistoryApi: '/api/user/getSurveyHistory',
    getAvatar: '/api/dummyUser/findAvatar',
    getUserProfileCategory: '/api/user/getUserProfileCategory',
    getUserSurveyParticipation:'/api/user/getUserSurveyParticipation',
    getPanelistPoints: '/api/user/getPanelistPoints',
    getPanelistRedeemHistory: '/api/user/getPanelistRedeemHistory',
    getReferralHistory: '/api/user/getReferralHistory',
    getPanelistPointHistory: '/api/user/getPanelistPointHistory',
    getCurrencyExchange: '/api/user/getCurrencyExchange',
    getCountryLanguage: "/api/panelist/getPanelistLanguage",
    getSurveyLinkWithQualification:'/api/user/getSurveyLinkWithQualification',
    getProfileCatQsn: '/api/user/getProfileCatQsn',
    getNoticationByNotificationId: '/api/user/getNotificationByNotificationId',
    getRewardType:'/api/user/getRewardType'
  
}
<div>
  <app-nav-menu></app-nav-menu>
<hr class=" container-fluid nav-devider mb-0">
<div class="container-fluid  createaccount-page">
  <div class="row joint-now">
    <div class="col-md-6">
      <div class="signup-header">
        {{'welcomeTo'|translate}}
        <span class="signup-text">{{'opinionEdge'|translate}}</span>
      </div>
      <div class="row text-center joinnow-template">
        <div class="col-md-4">
          <div><img src="../assets/image/certificate.png" alt=""></div>
          <div class="mt-4 mb-3">
            <i class="fa-sharp fa-regular fa-circle-dot text-primary"></i>
          </div>
          <div>{{'joinPanel'|translate}}</div>
        </div>
        <div class="col-md-4">
          <div><img src="../assets/image/rewards.png" alt=""></div>
          <div class="mt-4 mb-3">
            <i class="fa-sharp fa-regular fa-circle-dot text-primary"></i>
          </div>
          <div>{{'earn_create'|translate}} <span class="text-warning">{{'rewards_create'|translate}}</span></div>
        </div>
        <div class="col-md-4">
          <div><img src="../assets/image/listening to-feedback.png" alt=""></div>
          <div class="mt-4 mb-3">
            <i class="fa-sharp fa-regular fa-circle-dot text-primary"></i>
          </div>
          <div>{{'shareYourOpinion'|translate}}</div>
        </div>
      </div>
      <div class="row joinnow-template-footer mb-3">
        <span class="fs-6">{{'shareYourOpinionTitle'|translate}}</span>
      </div>
    </div>
    <div class="col-md-1 px-0"></div>
    <div class="col-md-5 signup-div">
      <div class="signup-form" *ngIf="isShowCreateForm">
        <form [formGroup]="createUserForm" (ngSubmit)="onCreateUser()" autocomplete="off">
          <div class="signup-data">
            <div class="row">
              <div class="join-header">{{'signUp'|translate}}</div>
            </div>
            <div class="div-formdata">
              <section class="mb-1">
                <div [ngClass]="createUserForm.get('email')?.invalid && createUserForm.get('email')?.touched ? 'invalid-input floating-group':'floating-group' ">
                  <input type="email" formControlName="email" class="form-control floating-input"
                    [placeholder]="'emailId'|translate" autocomplete="off">
                </div>
                <hr class="join-page-hr">
              </section>
              <section class="row  mb-1">
                <div class="col-md-6">
                  <div [ngClass]="createUserForm.get('firstName')?.invalid && createUserForm.get('firstName')?.touched ? 'invalid-input floating-group':'floating-group' ">
                    <input type="text" formControlName="firstName" class="form-control floating-input"
                      [placeholder]="'firstName'|translate" autocomplete="off" (keypress)="validateInputText($event)" (paste)="false">
                  </div>
                  <hr class="join-page-hr">
                </div>

                <div class="col-md-6">
                  <div [ngClass]="createUserForm.get('lastName')?.invalid && createUserForm.get('lastName')?.touched ? 'invalid-input floating-group':'floating-group' ">
                    <input type="text" formControlName="lastName" class="form-control floating-input"
                      [placeholder]="'lastName'|translate" autocomplete="off" (keypress)="validateInputText($event)" (paste)="false">
                  </div>
                  <hr class="mt-0">
                </div>

              </section>
              <section class=" row mb-1">
                <div [ngClass]="(createUserForm.get('getMonth').invalid && createUserForm.get('getMonth').touched) ? 'invalid-selection col-md-6' : 'col-md-6'">
                  <select formControlName="getMonth" class="form-select px-3 join-select">
                    <option value="" disabled>{{'birthMonth'|translate}}</option>
                    <option *ngFor="let month of getMonths(); index as i" [value]="i+1">{{month}}</option>
                  </select>
                  <hr class="join-page-hr">
                </div>
                <div [ngClass]="(createUserForm.get('getYear').invalid && createUserForm.get('getYear').touched)? 'col-md-6 invalid-selection':'col-md-6 '">
                  <select formControlName="getYear" class="form-select px-3 join-select">
                    <option value="" disabled>{{'birthYear'|translate}}</option>
                    <option *ngFor="let years of getYears(); index as i;" [value]="years">{{years}}</option>
                  </select>
                  <hr class="join-page-hr">
                </div>
              </section>
              <section class="row  mb-1">
                <div class="col-md-6 px-2">
                  <div class="floating-group dropend">
                    <div [ngClass]="createUserForm.get('password')?.invalid && createUserForm.get('password')?.touched ? 'invalid-input input-group':'input-group' ">
                      <input
                      type="password" 
                      formControlName="password" 
                      [placeholder]="'password'|translate"  
                      (focus)="showCriteria = true" 
                      (blur)="showCriteria = false"
                      (input)="checkPasswordStrength($event)"
                      [type]="!show ? 'password' : 'text'" 
                      class="form-control floating-input dropdown-toggle dropdown-toggle-split"
                      required 
                      autocomplete="off" 
                      data-bs-toggle="dropdown" 
                      aria-expanded="false">
                      <span class=" cursor-pointer px-2" (click)="openPassword()" *ngIf="!show"><i
                          class="fa-regular fa-eye-slash py-2"></i></span>
                      <span class="cursor-pointer px-2" (click)="openPassword()" *ngIf="show"><i
                          class="fa-sharp fa-regular fa-eye py-2"></i></span>
                          <div class="password-criteria" *ngIf="showCriteria">
                            <ul class="p-0 fs-9 text-secondary mb-0">
                              <li [ngClass]="{'text-success success-password':passwordStrength?.hasLetter, 'text-danger error-password': !passwordStrength?.hasLetter}">{{'containLetter'| translate}}</li>
                              <li [ngClass]="{'text-success success-password': passwordStrength?.hasNumber, 'text-danger error-password': !passwordStrength?.hasNumber}">{{'containNumber'| translate}}</li>
                              <li [ngClass]="{'text-success success-password': passwordStrength?.hasSpecialChar, 'text-danger error-password': !passwordStrength?.hasSpecialChar}">{{'containCharacter'| translate}}</li>
                              <li [ngClass]="{'text-success success-password': passwordStrength?.hasMinLength, 'text-danger error-password': !passwordStrength?.hasMinLength}">{{'contain8Characters'| translate}}</li>
                              <li *ngIf="passwordStrength?.hasSpace" [ngClass]="{'text-success success-password': !passwordStrength?.hasSpace, 'text-danger error-password': passwordStrength?.hasSpace}">{{'noSpace'| translate}}</li>
                            </ul>
                          </div>
                    </div>
                    <hr class="join-page-hr">
                    
                  </div>
                </div>
                <div class="col-md-6 px-2">
                  <div class="floating-group">
                    <div [ngClass]="createUserForm.get('confirmPassword')?.invalid && createUserForm.get('confirmPassword')?.touched || createUserForm.hasError('passwordMismatch') ? 'invalid-input input-group':'input-group' ">
                      <input type="password" formControlName="confirmPassword" [placeholder]="'confirmPassword'|translate"
                        [type]="!showConfirm ? 'password' : 'text'" value="" class="form-control floating-input"
                         required autocomplete="off">
                      <span class=" cursor-pointer px-2" (click)="openConfirmPassword()" *ngIf="!showConfirm"><i
                          class="fa-regular fa-eye-slash py-2"></i></span>
                      <span class="cursor-pointer px-2" (click)="openConfirmPassword()" *ngIf="showConfirm"><i
                          class="fa-sharp fa-regular fa-eye py-2"></i></span>
                          
                    </div>
                    <hr class="m-0">
                    <div class="text-secondary fs-7" *ngIf="createUserForm.hasError('passwordMismatch')">
                      {{'confirmPasswordMessage'|translate}}
                    </div>
                  </div>
                </div>
               
              </section>
              <section class="row mb-1">
                <div class="col-md-8">
                  <div class="row pt-1">
                    <div [ngClass]="(createUserForm.get('language').invalid && createUserForm.get('language').touched)? 'col-md-6 invalid-selection':'col-md-6 '">
                      <select (change)="onLanguageSelect($event)" class="form-select join-select"
                        formControlName="language">
                        <option value="" disabled selected>{{'language'|translate}}</option>
                        <option *ngFor="let item of getCountryLanguage" >
                          {{item.countryLanguage}}
                        </option>
                      </select>
                      <hr class=" mt-1 join-page-hr">
                    </div>
                    <div [ngClass]="(createUserForm.get('gender').invalid && createUserForm.get('gender').touched)? 'col-md-6 invalid-selection':'col-md-6 '" >
                      <select class="form-select join-select" (change)="onSelectGender($event)"  formControlName="gender">
                        <option value="" disabled selected>{{'gender'|translate}}</option>
                        <option   *ngFor="let item of gender?.option">{{item.optionStatement}}</option>
                        <!-- <option value="Female">{{item.optionStatement}}</option> -->
                      </select>
                      <hr class=" mt-1 join-page-hr">
                    </div>
                  </div>
                  
                </div>
                <div class="col-md-4">
                  <div class="mb-1 floating-group">
                    <div [ngClass]="createUserForm.get('zipCode')?.invalid && createUserForm.get('zipCode')?.touched ? 'invalid-input input-group':'input-group' ">
                      <input type="text" formControlName="zipCode" [placeholder]="'zipCode'|translate"
                        class="form-control floating-input" (input)="onZipValidation($event)" [disabled]="countryId === null">
                    </div>
                    <hr class="join-page-hr">
                  </div>
                </div>
              </section>

              <section class="row" *ngIf="opinionEdgeHearOption">
                   <div class="col-md-12">
                    <div [ngClass]="(createUserForm.get('opinionEdgeHear').invalid && createUserForm.get('opinionEdgeHear').touched)? 'invalid-selection':''" >
                      <select class="form-select join-select" formControlName="opinionEdgeHear">
                        <option value="" disabled selected>{{hearOpinionEdgeQuestion || ''}}</option>
                        <option  [value]="item.optionStatement" *ngFor="let item of opinionEdgeHearOption">{{item.optionStatement}}</option>
                        <!-- <option value="Female">{{item.optionStatement}}</option> -->
                      </select>
                      <hr class=" mt-1 join-page-hr">
                    </div>
                   </div>
              </section>
              <!-- <div class="row">
                <div class="mb-3 px-3">
                  <ngx-recaptcha2 [siteKey]="recaptchaSiteKey" [hl]="getCulture"
                    formControlName="recaptcha" class="recaptcha">
                  </ngx-recaptcha2>
                </div>
              </div> -->
              <section class="row justify-content-end privacypolicy">
                <div [ngClass]="createUserForm.get('acceptTerms')?.invalid && createUserForm.get('acceptTerms')?.touched ?'form-check privacy-policycheck text-left p-0 text-danger':'form-check privacy-policycheck text-left p-0'" >
                  <input class="form-check-input mx-2" type="checkbox" formControlName="acceptTerms" id="chkDefault" >
                  <label class="form-check-label cursor-pointer" for="chkDefault">
                    {{'privacyAcceptMessage'|translate}} <a routerLink="/privacy-policy/terms-conditions" class="privacy-anchor">{{'termsConditions'|translate}}</a> {{'inThe'|translate}}
                  </label>
                  <a routerLink="/privacy-policy" class="privacy-anchor mx-1">{{'privacyPolicy'|translate}}</a>
                </div>
                <small></small>
                <div class="row privacy-policybtn">
                  <button type="submit"  class="btn btn-primary signup-btn" *ngIf="!isShowLoader">{{'submitBtn'|translate}}</button>
                  <button class="btn btn-primary signup-btn" *ngIf="isShowLoader">
                    <div class="spinner-border spinner-border-sm" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </button>
                </div>
              </section>
            </div>
          </div>
        </form>
      </div>
      <div class="verification-div" *ngIf="isShowSuccessMessage">
        <div class="text-center">
          <div class="text-center circle-check-div">
            <i class="fa-sharp fa-solid fa-circle-check"></i>
          </div>
          <div>
            <h2 class="fw-bold">{{'thanksHeader'|translate}}</h2>
            <div class="verifymessage-box fs-6">
              {{'thanksSubHeader'|translate}} <strong>{{emailLink}}</strong>. {{'messageEmailVerify'|translate}}
            </div>
            <div class="mt-3">
              <div class="mt-3 text-center">{{'please'|translate}} <a class="text-primary redirectlogin" routerLink="/">{{'clickHere'|translate}}</a> {{'toLogIn'|translate}}</div>
            </div>
            <div class="mt-3 fs-6 mb-3">
              <div class="mt-3 text-center">{{'getInTouch'|translate}} </div>
              <div><span class="text-primary">support@opinion-edge.com</span></div>
            </div>
          </div>
        </div>
      </div>
      <div class="verification-div mt-5" *ngIf="showMessage">
        <div class="text-center">
          <div class="text-center text-primary mb-2">
            <i class="fa-sharp fa-solid fa-triangle-exclamation fs-1"></i>
          </div>
          <div>
            <h5 class="text-secondary">{{showMessage}}</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
<!-- <app-footer class="privacy-policyfooter"></app-footer> -->